const workshops = [
  {
    name: 'اتوماتا',
    image: '/ZeroJourneyer/workshops/Autumata.png',
    description: [
      'هیچ وقت فکر کرده بودین که چند تا نقطه و خط بتونه شما رو از مرگ نجات بده؟! آیا برای فرار از این مرگ فقط یک جواب وجود داره؟',
      'در نظریه اتوماتا سعی می‌کنیم ماشین‌های ساده‌ای طراحی کنیم که بتونیم با اون‌ها مسائل اطرافمون رو حل کنیم. ماشین‌هایی که در طول تاریخ، نمونه‌های زیادی ازشون بوده و اگه خیلی بریم عقب، چرتکه رو میبینیم که یکی از همین نمونه‌هاست.',
      '"می‌توان ماشینی ساخت که هر مسئله‌ای که قابل حل باشد را محاسبه کند!" و این نتیجه‌ای بود که آلن تورینگ در سال ۱۹۳۶ در یک مقاله‌ بهش رسید و مسیر طراحی این ماشین‌ها رو تکمیل کرد.',
      'در این کارگاه، ساختار ماشین هایی رو یاد می‌گیریم که در یکی دو قرن اخیر، معرفی شدن؛ که ماشین تورینگ شاخص‌ترین‌ اون‌هاست. همینطور، سعی می‌کنیم با استفاده از اون‌ها، یه سری مسئله با هم حل کنیم.',
      'قراره با هم قدم به دنیای جدیدی بذاریم که هر قدممون اونجا معنای متفاوتی داره! با ما همراه باشین تا با هم، مسیرهایی رو پیدا کنیم که شما رو به جایی که میخواین برسونه :)))',
    ],
  },
  {
    name: 'سیستم‌های پیچیده',
    image: '/ZeroJourneyer/workshops/ComplexSystem.png',
    description: [
      'تا حالا فکر کردین کبوترها موقع مهاجرت فصل سرما چطوری شکل ۷ می‌گیرن؟ یا ماهی ها چطور حرکت های دست جمعی جالبی انجام میدن اون هم بدون این‌که خودشون ازین حرکت های جالب خبر داشته باشن و کسی رهبریشون رو برعهده بگیره؟ یا مورچه‌ها چطوری برای جابجا کردن آذوقه کارهای محیر‌العقول می‌کنند؟ یا چطوری یه مُد یا یه ویروس توی جامعه گسترش پیدا می‌کنه؟ یا حتی مغز ما چطوری با یه سری نورون و سیناپس تفکر می‌کنه؟ و یا آیا تا حالا اسم "اثر پروانه‌ای" به گوشتون خورده؟ منطق و علم پشتش چیه؟ و و و... .',
      'مثال ها خیلی زیادن و جالب اینجاست که این مثال ها محدود به هیچ علم خاصی نیستن. شناخت سیستم های پیچیده به ما کمک می کنه بهتر درک کنیم که تفاوت ها و فاصله ظاهری بین شاخه های مختلف علوم اعم از فیزیک، زیست شناسی، روانشناسی، جامعه شناسی، تاریخ، ترمودینامیک، صرفاً از درک ناقص ما و البته متدولوژی چند قرن اخیر ما در درک و تفسیر جهان ریشه می گیره و این علوم بر خلاف درک برخی از ما، از موضوعات متفاوتی صحبت نمیکنن.',
      'توی کارگاه اول می‌خوایم با چند تا از نمونه‌ سیستم‌های پیچیده دقیق‌تر آشنا بشیم. بعدش یه ابزار ریاضی خیلی جالب رو می‌شناسیم که از مهم‌ترین وسیله‌ها توی تحلیل این‌طور مسائله هاست. بعد سعی می‌کنیم چند تا از سیستم‌های پیچیده رو با کمک این ابزارمون مدل‌سازی کنیم و یه سری شبیه سازی جالب ببینیم.',
    ]

  },
  {
    name: 'سیستم‌های پیشنهاد‌دهنده',
    image: '/ZeroJourneyer/workshops/Recommender.png',
    description: [
      'کسی می‌تونه برای شما هدیه‌ی خوب بگیره که علاوه بر داشتن ذوق و سلیقه، از سلیقه‌ی شما خوب اطلاع داشته باشه و کسی می‌تونه به شما پیشنهادای خوبی بده، که شما رو خوب بشناسه.',
      'این روزا، سیستم‌های کامپیوتری زیادی هستند که کارشون پیشنهاد دادن به آدم‌هاست، پیشنهاد در خرید،‌ فیلم یا کتاب، انتخاب دوست یا دیدن پست دیگران در شبکه‌های اجتماعی.',
      'این سیستم‌ها خیلی وقتا خیلی هم کارشون رو خوب انجام می‌دن، ولی تا حالا فکر کردید چطور؟ این سیستم‌ها ما رو از کجا انقدر خوب می‌شناسن و چطور می‌تونند پیشنهادی بدن که ما خوشمون بیاد؟',
      'اگه دوست دارید که جواب این سوال‌ها رو پیدا کنید،  کارگاه سیستم‌های پیشنهاددهنده منتظر شماست!',
    ],
  },
  {
    name: 'ترکیبیات',
    image: '/ZeroJourneyer/workshops/tarkibiat.png',
    description: [
      'امروز مثل هر روز احمد اقا کلاه فروش کلی مشتری داره و مغازه‌اش غلغله هست از مردمی که کلاه میخوان. احمد اقا دوست داره به همه کلاهی برسه که دوست دارن؛ می‌دونیم هم که هر کس توی مغازه‌ی احمد آقا یه سری از کلاه‌ها رو دوست داره.',
      'احمد آقا مثل روزهای دیگه خیلی شانسی کلاه‌ها رو به مردم میده و واسه چند نفر کلاه کم میاد ولی امروز شما توی مغازه‌اید و میتونید بررسی کنید که آیا میشه به همه کلاه برسه یا نه؟',
      'جواب این سوال رو قراره با هم توی کارگاه ترکیبیات یاد بگیریم.',
    ],
  },

  {
    name: 'نظریه‌بازی‌ها',
    image: '/ZeroJourneyer/workshops/game.png',
    description: [
      'مرغ همسایه‌تون غازه؟',
      'نگران نباشید! میدونستید همسایه‌ها میتونن یه‌طوری حیوانات خونگی‌شون رو با هم جا‌به‌جا کنن که همه‌شون غاز داشته باشن؟!',
      'فرض کنید توی یک خونه سه نفر زندگی میکنن. این خونه سه تا اتاق داره که هرکدوم ویژگی‌های مختلفی دارن؛ بزرگ و کوچیک، تاریک و روشن و خلاصه همه‌جور. این سه تا همخونه‌ی ما حرفشون شده که هرکسی کدوم اتاق رو بر داره، یکی شون هم این وسط میگه اگه اتاق کوچک رو برداره روزی ۷ ساعت باید تلویزیون دستش باشه! اون یکی میگه اگه اتاق تاریکه رو برداره روزی ۸ ساعت کنترل رو میخواد (دلش میگیره بالاخره) و خلاصه هرکدومشون یه لیست بلند بالا درست کردن از خواسته‌ها و شروطشون. ',
      'سوال اینه که آیا میتونیم شرایطی رو ایجاد کنیم که این سه تا دوست رو راضی نگه داریم؟ تو این وضعیت از ما کمک خواستن اونم زیاد. در طول این کارگاه با هم به این فکر میکنیم که چطوری در این شرایط، و شرایط مشابه میشه طوری تصمیم‌گیری کرد که هزینه کمتری رو متحمل بشیم.',
    ],
  },
]

export default workshops;