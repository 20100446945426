const workshops = [
  {
    name: 'نظریه بازی',
    image: '/game_theory.jpg',
  },
  {
    name: 'بلاک‌چین',
    image: '/blockchain.jpg',
  },
  {
    name: 'گرانش',
    image: '/granesh.jpg',
  },
  {
    name: 'هوش مصنوعی',
    image: '/ai_pic.jpg',
  },
  {
    name: 'الگوریتم',
    image: '/algo.jpg',
  },
  {
    name: 'نظریه‌ی کدگذاری',
    image: '/coding.jpg',
  },
]

export default workshops;
