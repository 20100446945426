import { AvatarGroup } from '@material-ui/lab';
import React from 'react';

const UsersAvatar = () => {
  return (
    <AvatarGroup max={4}>
      {/* <Avatar>ر</Avatar>
      <Avatar>س</Avatar>
      <Avatar>م</Avatar> */}
    </AvatarGroup>
  );
};

export default UsersAvatar;
