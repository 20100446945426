const questions = [
  {
    text: 'رویداد برای کدوم دانش‌آموزاس؟',
    answerParagraphs: ['بچه‌های ۱۰ام تا ۱۲ام می‌تونن توی رویداد شرکت کنن.'],
  },

  {
    text: 'پایه دانش‌آموزها بر اساس چه معیاری انتخاب شده؟',
    answerParagraphs: [
      'در جریان رویداد شما تعدادی سوال معماگونه و ریاضی حل خواهید کرد که برای حل اون‌ها، باید از برخی دانش‌های قبلی برخوردار باشید.',
    ],
  },

  {
    text: 'جزئیات رویداد رو از کجا بفهمیم؟',
    answerParagraphs: [
      'جزئیات رویداد به صورت کامل تا تاریخ ۸ شهریور روی کانال تلگرامی رویداد (@RastaihaOnline) اعلام می‌شه! و هم‌چنین از اینستاگرام ما هم غافل نباشید. (@Rastaiha)',
    ],
  },

  {
    text: 'رویداد فردیه یا گروهی؟',
    answerParagraphs: [
      'گروهی! شما در گروه‌های ۳ نفره مراحل کارگاه‌ها رو رد می‌کنید و با امتیازی که بدست میارید تو مسابقه نهایی شرکت می‌کنید.',
    ],
  },

  {
    text: 'من که خونه نیستم چی؟',
    answerParagraphs: [
      'اولا که توصیه می‌کنیم برای جلوگیری از شیوع کرونا خونه بمونید؛ اما در این صورت هم اصلاً کل رویداد ما بر بستر اینترنت هست و وبسایت رویداد هم با لپ‌تاپ و کامپیوتر و هم با گوشی قابل دسترسی‌ه!',
    ],
  },

  {
    text: 'در چه صورتی می‌تونیم توی رویداد شرکت کنیم؟',
    answerParagraphs: [
      'تمام دانش‌آموزان پایه‌های دهم تا دوازدهم میتونن توی رویداد شرکت کنن. برای ثبت‌نام ما دوتا سوال ورودی داریم که باید حل کنین و جوابشو برامون بفرستین (نگران نباشید. سوال‌ها سخت نیستن!) بر اساس جوابی که به اون سوال‌ها می‌دید و یکسری فاکتور دیگه مثل زمان ثبت‌نام ما ۲۰۰ نفر رو برای شرکت در مدرسه تابستانه رستا انتخاب می‌کنیم. البته هدف ما از سوال دادن بیشتر اینه که ببینیم کی اهل فکر کردن‌ه و جواب آخر خیلی هم برامون مهم نیست.',
    ],
  },

  {
    text: 'گروه‌ها چند نفره‌اس؟',
    answerParagraphs: ['هر گروه ۳نفره‌اس!'],
  },

  {
    text: 'اعضای یک گروه می‌تونن از پایه‌های مختلف باشن؟',
    answerParagraphs: [
      'بله اعضای یک گروه می‌تونن از هرکدوم از پایه‌های دهم تا دوازدهم باشن و محدودیتی برای اینکه هم‌پایه باشن وجود نداره.',
    ],
  },
];

export default questions;
